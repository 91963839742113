/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-13 16:29:40
 * @Module: 修改案件费用
 */


<template>
  <el-dialog :title="
      type == 'add' ? '添加案件费用' : type == 'revision' ? '修改案件费用' : ''
    " :visible.sync="dialogVisible" width="800px" @closed="closed">
    <div style="padding: 20px">
      <el-form size="mini" label-position="top" label-width="120px" :model="form" ref="form">
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="项目" prop="item" v-if="type === 'add'">
              <el-input v-model="form.item" placeholder="请输入项目名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="金额" prop="amount">
              <el-input v-model.number="form.amount" placeholder="请输入金额"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="谁付" prop="payer">
              <!-- <el-input
                v-model="form.payer"
                placeholder="谁付这笔费用？"
              ></el-input> -->
              <el-select v-model="form.payer" placeholder="谁付这笔费用？" style="width: 100%">
                <el-option key="我司垫付" label="我司垫付" value="我司垫付" />
                <el-option key="客户自付" label="客户自付" value="客户自付" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="付费进度" prop="paid">
              <el-select v-model="form.paid" placeholder="请选择" style="width: 100%">
                <el-option label="已完成" :value="true" />
                <el-option label="未完成" :value="false" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="需要报销" prop="reimburseAmount">
              <el-input v-model.number="form.reimburseAmount" placeholder="请输入需要报销金额"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报销进度" prop="reimbursed">
              <el-select v-model="form.reimbursed" placeholder="请选择" style="width: 100%">
                <el-option label="已完成" :value="true" />
                <el-option label="未完成" :value="false" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发票" prop="invoiced">
              <el-select v-model="form.invoiced" placeholder="请选择" style="width: 100%">
                <el-option label="已开" :value="true" />
                <el-option label="未开" :value="false" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="note">
              <el-input v-model="form.note" type="textarea" :autosize="{ minRows: 4, maxRows: 12 }" placeholder="请输入备注">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data () {
    return {
      type: "add",
      dialogVisible: false,
      form: {
        item: "",
        amount: 0,
        payer: "",
        paid: false,
        reimburseAmount: 0,
        reimbursed: false,
        note: "",
        invoiced: "",
      },
    };
  },
  mounted () { },
  methods: {
    show ({ type = "add", data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
      }
      this.type = type;
      this.dialogVisible = true;
    },
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postLawsuitPhase();
        } else {
          return false;
        }
      });
    },
    close () {
      // this.form = { ...this.form, ...this.oldform };
      this.dialogVisible = false;
    },
    closed () {
      this.form = {
        lawsuitUuid: "",
        content: "",
        finished: false,
        finishedAt: "",
        note: "",
      };
    },
    async postLawsuitPhase () {
      const api = {
        revision: {
          func: this.$api.putChangeLawsuitCost,
          params: {
            ...this.form,
            lawsuitUuid: this.form.uuid,
          },
        },
        add: {
          func: this.$api.postLawsuitPhase,
          params: {
            ...this.form,
          },
        },
      };

      const { status, data, info } = await api[this.type].func(
        api[this.type].params
      );
      if (status === 200) {
        this.$message({
          type: "success",
          message: info,
        });
        this.$emit("success", this.type);
        this.dialogVisible = false;
      } else {
        this.$message({
          type: "error",
          message: info,
        });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
</style>
