/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-25 10:27:57
 * @Module: 分佣列表
 */
<template>
  <div>
    <div class="top">
      <el-row :gutter="24">
        <el-col :span="6">
          <div class="top-item item1">
            <div class="left"></div>
            <div class="right">
              <div class="right-t">
                {{ commissionProfile.total | priceFormat }}
              </div>
              <div class="right-b">总收入</div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="top-item item2">
            <div class="left"></div>
            <div class="right">
              <div class="right-t">
                {{ commissionProfile.withdraw | priceFormat }}
              </div>
              <div class="right-b">总提现</div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="top-item item4">
            <div class="left"></div>
            <div class="right">
              <div class="right-t">
                {{ commissionProfile.pending | priceFormat }}
              </div>
              <div class="right-b">提现中</div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="top-item item3">
            <div class="left"></div>
            <div class="right">
              <div class="right-t">
                {{ commissionProfile.balance | priceFormat }}
              </div>
              <div class="right-b">余额</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div style="mine-height:68px;background:#fff;dispaly:flex; align-items: center;	padding: 0 16px;overflow:hidden;  margin-top: 24px;">
      <searchForm :searchForm.sync="listParams" @submit="searchFormFn" />
    </div>

    <div style="padding:16px;background:#fff;border-radius: 4px;padding-top:0px;margin-top:20px">
      <div style="display: flex;	justify-content: space-between;	align-items: center;height:56px;">
        <div>分佣列表</div>
        <!-- <span v-if="['ADMIN'].includes(user.role)"> -->
        <el-button-group>
          <el-button type="primary" size="small" @click="()=>$router.push({path:'/withdrawAppProcess'})" v-if="['ADMIN','PROMOTER','SUPER'].includes(user.role)">分佣提现申请</el-button>
          <el-button type="primary" size="small" @click="add" v-if="['ADMIN','SUPER'].includes(user.role)">添加分佣</el-button>
          <!-- <el-button type="primary" size="small" @click="add" v-if="['ADMIN'].includes(user.role)">添加流水</el-button> -->
        </el-button-group>
        <!-- <el-button type="primary" size="small" @click="add">添加分佣</el-button> -->
        <!-- </span> -->
      </div>
      <el-table size="mini" @sort-change="sortChange" :data="list" tooltip-effect="dark" style="width: 100%;margin-top:10px;">
        <el-table-column label="单号" prop="promoter.number" show-overflow-tooltip width="100">
          <template slot-scope="scope">{{ scope.row.deal?scope.row.deal.number :"--"}}</template>
        </el-table-column>
        <el-table-column label="成单日期" prop="deal.createdAt" sortable="deal.createdAt" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.deal&&scope.row.deal.createdAt">{{ scope.row.deal.createdAt|timeFormat }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="成单金额" prop="deal.amount" sortable="deal.amount" show-overflow-tooltip width="100">
          <template slot-scope="scope">{{ scope.row.deal?scope.row.deal.amount :"--"}}</template>
        </el-table-column>
        <el-table-column label="客户名称" prop="deal.customerName" show-overflow-tooltip width="100">
          <template slot-scope="scope">{{ scope.row.deal?scope.row.deal.customerName :"--"}}</template>
        </el-table-column>
        <el-table-column label="项目名称" prop="deal.projectName" show-overflow-tooltip width="100">
          <template slot-scope="scope">{{ scope.row.deal?scope.row.deal.projectName :"--"}}</template>
        </el-table-column>
        <el-table-column label="项目介绍" prop="deal.projectIntro" show-overflow-tooltip width="100">
          <template slot-scope="scope">{{ scope.row.deal?scope.row.deal.projectIntro :"--"}}</template>
        </el-table-column>

        <el-table-column label="会员" prop="promoter.name" show-overflow-tooltip width="100">
          <template slot-scope="scope">{{ scope.row.promoter?scope.row.promoter.name :"--"}}</template>
        </el-table-column>
        <el-table-column label="分佣金额" prop="amount" sortable="amount" show-overflow-tooltip width="100">
          <template slot-scope="scope"> <span v-if="scope.row.type == 'WITHDRAW'" style="color: #ff0d0d">-¥{{ scope.row.amount }}</span>
            <span v-else style="color: #0ac258">+¥{{ scope.row.amount }}</span>
          </template>

        </el-table-column>

        <el-table-column label="打款人" prop="payer" show-overflow-tooltip width="140">
          <template slot-scope="scope">{{ scope.row.payer ||"--"}}</template>
        </el-table-column>

        <el-table-column label="收款方式" prop="payment" show-overflow-tooltip width="140">
          <template slot-scope="scope">{{ scope.row.payment ||"--"}}</template>
        </el-table-column>

        <el-table-column label="户名" prop="accountName" show-overflow-tooltip width="140">
          <template slot-scope="scope">{{ scope.row.accountName ||"--"}}</template>
        </el-table-column>
        <el-table-column label="账号" prop="promoter.bankAccount" show-overflow-tooltip width="140">
          <template slot-scope="scope">{{ scope.row.bankAccount}}</template>
        </el-table-column>

        <el-table-column label="备注" prop="note" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.note ||"--"}}</template>
        </el-table-column>
        <el-table-column label="分佣日期" prop="createdAt" sortable="custom" show-overflow-tooltip width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.createdAt">{{ scope.row.createdAt|timeFormat }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="110" :resizable="false" v-if="['ADMIN','SUPER'].includes(user.role)">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="()=>revision(scope.row)">编辑</el-button>
            <el-button type="text" size="mini" @click="()=>deleteData(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <c-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getCommissionList" />
    </div>
    <commissionForm ref="commissionForm" @success="getCommissionList" />
  </div>
</template>
 <script>
import searchForm from "@/views/Commission/components/searchForm"
import commissionForm from "@/views/Commission/components/commissionForm"
import { mapState } from "vuex";
export default {
  components: {
    searchForm,
    commissionForm
  },
  data () {
    return {
      list: [],
      promoterTreeList: [],
      listParams: {
        keyword: '',
        promoterUuid: '',
        type: '',
        grade: '',
        orderBy: '',
        order: ''
      },
      commissionProfile: {
        balance: 0,
        total: 0,
        withdraw: 0,
        pending: 0
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user
    })
  },
  mounted () {
    this.getCommissionList();
    this.getCommissionProfile()
  },
  methods: {
    searchFormFn () {
      const { promoterUuid } = this.listParams
      this.getCommissionList();
      this.getCommissionProfile({ promoterUuid })
    },
    async getCommissionProfile (params = {}) {
      const { status, data } = await this.$api.getCommissionProfile(params);
      console.log(status, data);
      if (status === 200) {
        this.commissionProfile = data;
      }
    },
    // 排序
    sortChange ({ order, prop }) {
      if (order) {
        this.listParams.order = order;
        this.listParams.orderBy = prop
      } else {
        this.listParams.order = '';
        this.listParams.orderBy = ''
      }
      this.getCommissionList()
    },
    add () {
      this.$refs.commissionForm.show({ type: 'add' })
    },
    revision (data) {
      this.$refs.commissionForm.show({
        type: 'revision', data: {
          ...data,
          dealUuid: data.deal ? data.deal.uuid : null,
          promoterUuid: data.promoter ? data.promoter.uuid : null,
        }
      })
    },
    async getCommissionList () {
      const { current, size } = this.$refs.pagination
      const { listParams } = this
      const { status, data } = await this.$api.getCommissionList({ current, size, ...listParams })
      if (status == 200) {
        this.list = data.records;
        this.$refs.pagination.createPagination(data)
      }
    },
    async deleteData ({ uuid }) {
      try {
        await this.$confirm(`此操作将永久删除记录, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const { status, info } = await this.$api.deleteCommission({ uuid })
        if (status === 200) {
          this.$message({
            type: 'success',
            message: info
          })
          this.getCommissionList()
        } else {
          this.$message({
            type: 'error',
            message: info
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
.top {
  &-item {
    height: 123px;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 20px;
    .left {
      width: 70px;
      height: 70px;
      margin-right: 16px;
    }
    .right {
      &-t {
        font-weight: 400;
        font-size: 30px;
        line-height: 45px;
        /* identical to box height */

        /* 字体/262626 */

        color: #262626;
      }
      &-b {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        /* 字体/666666 */

        color: #666666;
      }
    }
  }
  .item1 {
    .left {
      background-image: url(~@/static/img/earnings_1.png);
    }
  }
  .item2 {
    .left {
      background-image: url(~@/static/img/earnings_2.png);
    }
  }
  .item3 {
    .left {
      background-image: url(~@/static/img/earnings_3.png);
    }
  }
  .item4 {
    .left {
      background-image: url(~@/static/img/earnings_4.png);
    }
  }
}
</style>