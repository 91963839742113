/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-28 11:12:02
 * @Module: 项目名称
 */
  <template>
  <el-select :value="value" placeholder="请选择项目名称" clearable @change="change" style="width:100%;">
    <el-option v-for="item in ['介绍学员','普通推广']" :key="item" :label="item" :value="item">
    </el-option>
  </el-select>
</template>
 <script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {
    change (value) {
      this.$emit('input', value)
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>