/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-06 13:37:41
 * @Module: 分账明细
 */
 <template>
  <div class="Earnings">
    <div class="top">
      <el-row :gutter="24">
        <el-col :span="6">
          <div class="top-item item1">
            <div class="left"></div>
            <div class="right">
              <div class="right-t">
                {{ percentageProfile.total | priceFormat }}
              </div>
              <div class="right-b">总收入</div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="top-item item2">
            <div class="left"></div>
            <div class="right">
              <div class="right-t">
                {{ percentageProfile.withdraw | priceFormat }}
              </div>
              <div class="right-b">总提现</div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="top-item item4">
            <div class="left"></div>
            <div class="right">
              <div class="right-t">
                {{ percentageProfile.pending | priceFormat }}
              </div>
              <div class="right-b">提现中</div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="top-item item3">
            <div class="left"></div>
            <div class="right">
              <div class="right-t">
                {{ percentageProfile.balance | priceFormat }}
              </div>
              <div class="right-b">余额</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div style="
        height: 68px;
        background: #fff;
        dispaly: flex;
        align-items: center;
        padding: 0 16px;
        overflow: hidden;
        margin-top: 24px;
      " v-if="['ADMIN','SUPER'].includes(user.role)">
      <searchForm :searchForm.sync="listParams" @submit="searchFormFn" />
    </div>

    <div class="content">
      <div class="title">
        <p>流水明细</p>
        <div>
          <el-button-group>
            <el-button type="primary" size="small" @click="()=>$router.push({path:'/withdrawApplication'})" v-if="['ADMIN','PARTNER','SUPER'].includes(user.role)">提现申请</el-button>
            <el-button type="primary" size="small" @click="add" v-if="['ADMIN','SUPER'].includes(user.role)">添加流水</el-button>
          </el-button-group>
        </div>
      </div>
      <div style="padding: 16px; padding-top: 0">
        <el-table size="mini" :data="list" tooltip-effect="dark" style="width: 100%; margin-top: 10px">
          <el-table-column label="日期" show-overflow-tooltip min-width="60" prop="date">
            <template slot-scope="scope">
              <span v-if="scope.row.date">{{
                scope.row.date | timeFormat
              }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>

          <el-table-column label="分成账户" show-overflow-tooltip min-width="60" prop="account" v-if="['ADMIN','SUPER'].includes(user.role)">
            <template slot-scope="scope">
              <span>{{ scope.row.account || "--" }}</span>
            </template>
          </el-table-column>

          <el-table-column label="动账" show-overflow-tooltip min-width="60" prop="amount">
            <template slot-scope="scope">
              <span v-if="scope.row.type == 'WITHDRAW'" style="color: #ff0d0d">-¥{{ scope.row.amount }}</span>
              <span v-else-if="scope.row.type == 'PERCENTAGE'" style="color: #0ac258">+¥{{ scope.row.amount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="余额" show-overflow-tooltip min-width="60" prop="balance">
            <template slot-scope="scope">
              <span>¥{{ scope.row.balance || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="事由" show-overflow-tooltip min-width="60" prop="cause">
            <template slot-scope="scope">
              <span>{{ scope.row.cause || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="打款人" show-overflow-tooltip min-width="60" prop="payer">
            <template slot-scope="scope">
              <span>{{ scope.row.payer || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="收款方式" show-overflow-tooltip min-width="60" prop="paymentType">
            <template slot-scope="scope">
              <span v-if="scope.row.paymentType" class="primary">{{
                scope.row.paymentType
              }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="户名" show-overflow-tooltip min-width="60" prop="accountName">
            <template slot-scope="scope">
              <span>{{ scope.row.accountName || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="账号" show-overflow-tooltip min-width="60" prop="accountNo">
            <template slot-scope="scope">
              <span>{{ scope.row.accountNo || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注" show-overflow-tooltip min-width="60" prop="note">
            <template slot-scope="scope">
              <span>{{ scope.row.note || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="110" :resizable="false" v-if="['ADMIN','SUPER'].includes(user.role)">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="() => revision(scope.row)">编辑</el-button>
              <el-button type="text" size="mini" @click="() => deletePercentage(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <c-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getPercentageList" />
    </div>
    <add ref="add" @success="getPercentageList" />
  </div>
</template>
 <script>
import { mapState } from "vuex";
import add from "@/views/Earnings/components/add";
import searchForm from "@/views/Earnings/components/searchForm";
export default {
  components: { add, searchForm },
  data () {
    return {
      list: [],
      percentageProfile: {
        balance: 0,
        total: 0,
        withdraw: 0,
        pending: 0
      },
      listParams: {
        accountUuid: "",
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.userInfo.user,
    }),
  },
  mounted () {
    this.getPercentageList();
    this.getPercentageProfile();
  },
  methods: {
    searchFormFn () {
      const { accountUuid } = this.listParams
      this.getPercentageList()
      this.getPercentageProfile({ accountUuid })
    },
    async getPercentageList () {
      const { current, size } = this.$refs.pagination;
      const { listParams } = this;
      const { status, data } = await this.$api.getPercentageList({
        current,
        size,
        ...listParams,
      });
      if (status == 200) {
        this.list = data.records;
        this.$refs.pagination.createPagination(data);
      }
    },
    async getPercentageProfile (params) {
      const { status, data } = await this.$api.getPercentageProfile(params);
      if (status === 200) {
        this.percentageProfile = data;
      }
    },
    add () {
      this.$refs.add.show({ type: "add" });
    },
    revision (data) {
      this.$refs.add.show({ type: "revision", data });
    },
    async deletePercentage ({ uuid }) {
      try {
        await this.$confirm(`此操作将永久删除此纪录，是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })

        const { status, info } = await this.$api.deletePercentage({ uuid })
        if (status == 200) {
          this.$message({
            message: info,
            type: 'success'
          })
          this.getPercentageList();
          this.getPercentageProfile();
        } else {
          this.$message({
            message: info,
            type: 'error'
          })
        }
      } catch (error) {
        console.log(error)
      }

    }
  },
};
</script>
 <style lang='scss' scoped>
.Earnings {
  .top {
    &-item {
      height: 123px;
      background: #fff;
      display: flex;
      align-items: center;
      padding: 0 20px;
      .left {
        width: 70px;
        height: 70px;
        margin-right: 16px;
      }
      .right {
        &-t {
          font-weight: 400;
          font-size: 30px;
          line-height: 45px;
          /* identical to box height */

          /* 字体/262626 */

          color: #262626;
        }
        &-b {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height */

          /* 字体/666666 */

          color: #666666;
        }
      }
    }
    .item1 {
      .left {
        background-image: url(~@/static/img/earnings_1.png);
      }
    }
    .item2 {
      .left {
        background-image: url(~@/static/img/earnings_2.png);
      }
    }
    .item3 {
      .left {
        background-image: url(~@/static/img/earnings_3.png);
      }
    }
    .item4 {
      .left {
        background-image: url(~@/static/img/earnings_4.png);
      }
    }
  }
  .content {
    background: #fff;
    margin-top: 23px;

    .title {
      font-size: 16px;
      display: flex;
      align-items: center;
      line-height: 56px;
      justify-content: space-between;
      padding: 0 16px;
    }
  }
}
</style>