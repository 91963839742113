/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-10 14:00:46
 * @Module: 文件上传
 */
 <template>
  <div v-if="dialogTableVisible">
    <el-dialog :append-to-body="true" title="文件上传" :loading="loading" :visible.sync="dialogTableVisible" width="400px">
      <div style="padding:20px">
        <el-upload drag ref="upload" class="upload-demo" :multiple="multiple" :accept="accept" :auto-upload="false" action="" :limit="limit">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">{{tip}}</div>
        </el-upload>

      </div>
      <div slot="footer">
        <el-button @click="submit" size="mini">点击上传</el-button>
      </div>
    </el-dialog>
  </div>

</template>
 <script>
import uploadFile from '@/utils/oss.js'  // 引入oss.js文件
export default {
  components: {},
  props: {
    limit: {
      type: Number,
      default: 1
    },
    multiple: {
      type: Boolean,
      default: false
    },
    tip: {
      type: String,
      default: '只能上传jpg/png文件'
    },
    accept: {
      type: String,
      default: 'image/gif, image/jpeg'
    }
  },
  data () {
    return {
      dialogTableVisible: false,
      loading: false
    };
  },
  mounted () { },
  methods: {
    async submit () {
      this.loading = true;
      const { uploadFiles } = this.$refs.upload;
      const { data } = await this.$api.getFileSts();
      let arr = [];
      uploadFiles.forEach(async item => {
        if (item.status == "ready") {
          const response = await uploadFile(item.raw, data);
          const { res: { status }, name } = response;
          console.log(response)
          if (status === 200) {
            arr.push(name)
            if (arr.length == uploadFiles.length) {
              this.loading = false
              this.$emit('success', arr);
              this.dialogTableVisible = false;
            }
          }
        }
      })
    },
    show () {
      this.dialogTableVisible = true
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>