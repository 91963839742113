/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-09 11:56:12
 * @Module: 添加流水
 */
 <template>
  <div>
    <el-dialog :title="type == 'revision' ? '编辑流水' : '添加流水'" :visible.sync="dialogVisible" width="40%" @closed="closed">
      <div style="padding: 20px">
        <el-form size="mini" label-position="top" label-width="80px" :model="form" ref="form">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="分成账号" prop="accountUuid" :rules="{ required: true, message: '分成账号不能为空' }">
                <accountShare v-model="form.accountUuid" />
                <!-- <el-select style="width:100%;" value-key="uuid" v-model="form.accountUuid" placeholder="请选择分账账号">
                  <el-option v-for="itm in accountShareList" :key="itm.uuid" :label="itm.name" :value="itm.uuid">
                  </el-option>
                </el-select> -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="动账类型" prop="type" :rules="{ required: true, message: '动账类型不能为空' }">
                <el-select style="width: 100%" value-key="value" v-model="form.type" placeholder="动账类型">
                  <el-option v-for="itm in [
                      { label: '分账', value: 'PERCENTAGE' },
                      { label: '提现', value: 'WITHDRAW' },
                    ]" :key="itm.value" :label="itm.label" :value="itm.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="动账金额" prop="amount" :rules="{ required: true, message: '动账金额不能为空' }">
                <el-input v-model="form.amount" placeholder="请输入动账金额"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="余额金额" prop="balance" :rules="{ required: true, message: '余额金额不能为空' }">
                <el-input v-model="form.balance" placeholder="请输入余额金额"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="事由" prop="cause" :rules="{ required: true, message: '事由不能为空' }">
                <el-input v-model="form.cause" placeholder="请输入事由"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="打款人" prop="payer" v-show="form.type == 'WITHDRAW'">
                <el-input v-model="form.payer" placeholder="请输入打款人姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收款方式" prop="paymentType" v-show="form.type == 'WITHDRAW'">
                <el-input v-model="form.paymentType" placeholder="请输入收款方式"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="户名" prop="accountName" v-show="form.type == 'WITHDRAW'">
                <el-input v-model="form.accountName" placeholder="请输入收款户名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="账号" prop="accountNo" v-show="form.type == 'WITHDRAW'">
                <el-input v-model="form.accountNo" placeholder="请输入收款账号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="日期" prop="date">
                <el-date-picker style="width: 100%" value-format="timestamp" v-model="form.date" type="date" placeholder="选择日期" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备注" prop="note">
                <el-input v-model="form.note" placeholder="请输入备注"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="submit" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 <script>
import accountShare from "@/views/CustomerManagement/components/form/accountShare";
export default {
  components: {
    accountShare,
  },
  data () {
    return {
      dialogVisible: false,
      type: "add",
      form: {
        accountUuid: "",
        amount: "",
        balance: "",
        cause: "",
        payer: "",
        paymentType: "",
        accountName: "",
        accountNo: "",
        note: "",
        date: null,
        type: "PERCENTAGE",
      },
    };
  },
  mounted () { },
  methods: {
    show ({ type = "add", data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
      }
      this.type = type;
      this.dialogVisible = true;
    },
    closed () {
      console.log('closed')
      this.form = {
        accountUuid: "",
        amount: "",
        balance: "",
        cause: "",
        payer: "",
        paymentType: "",
        accountName: "",
        accountNo: "",
        note: "",
        date: null,
        type: "PERCENTAGE",
      };
    },

    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postPercentage();
        } else {
          return false;
        }
      });
    },
    async postPercentage () {
      let { form } = this;
      if (form.type == "PERCENTAGE") {
        form.payer = "";
        form.paymentType = "";
        form.accountName = "";
        form.accountNo = "";
      }
      const api = {
        revision: {
          func: this.$api.putChangePercentage,
          params: {
            ...form,
            // uuid: this.$route.params.uuid,
            // related: related.toString()
          }
        },
        add: {
          func: this.$api.postPercentage,
          params: {
            ...form,
            // related: related.toString()
          }
        }
      }
      const { status, info } = await api[this.type].func(api[this.type].params);
      if (status === 200) {
        this.$message({
          message: info,
          type: "success",
        });
        this.$emit("success");
        this.dialogVisible = false;
      } else {
        this.$message({
          message: info,
          type: "error",
        });
      }
    },
  },
};
</script>
 <style lang='scss' scoped>
</style>