/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-23 11:52:48
 * @Module: 渠道
 */

 <template>
  <div>
    <el-select :value="value" placeholder="请选择渠道" clearable @change="change" style="width:100%;">
      <el-option v-for="item in ['是','否','禁']" :key="item" :label="item" :value="item">
      </el-option>
    </el-select>
  </div>
</template>
 <script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {
    change (value) {
      this.$emit('input', value)
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>