/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-17 10:29:50
 * @Module: 筛选表单
 */
 <template>
  <el-form :inline="true" :model="searchForm" size="mini" style="margin-top:20px;">
    <el-form-item label="公司名称">
      <el-input v-model="searchForm.keyword" placeholder="搜索公司名称关键词" clearable></el-input>
    </el-form-item>

    <el-form-item label="对接法务">
      <!-- <el-select v-model="searchForm.legalName" filterable placeholder="请选择对接法务" clearable>
        <el-option v-for="item in legals" :key="item" :label="item" :value="item">
        </el-option>
      </el-select> -->
			  <legal v-model="searchForm.legalUuid"  clearable/>
    </el-form-item>
    <el-form-item label="当前状态">
      <el-select v-model="searchForm.state" filterable placeholder="请选择当前状态" clearable>
        <el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="团队">
      <team v-model="searchForm.team" team="仲达,仲管,速裁,不倒翁" />
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit">查询</el-button>
    </el-form-item>
  </el-form>

</template>
 <script>
import team from "@/views/CaseManagement/components/form/team"
import legal from "@/views/CustomerManagement/components/form/legal"
export default {
  components: {
    team,
		legal
  },
  props: {
    searchForm: {
      type: Object,
      default: () => ({
        keyword: '',
        legalUuid: '',
        team: ''
      })
    }
  },
  data () {
    return {
      legals: [],
      stateList: [{
        label: '停费',
        value: 0
      },
      {
        label: '正常',
        value: 1
      }]
    };
  },
  mounted () {
    // this.getOuterCompanyFilter();
  },
  methods: {
    async getOuterCompanyFilter () {
      const { data, status } = await this.$api.getOuterCompanyFilter()
      if (status === 200) {
        this.legals = data.legals;
      }
    },
    onSubmit () {
      this.$emit('submit')
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>