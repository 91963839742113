/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-06 13:39:23
 * @Module: 内部案件
 */
<template>
  <div class="CaseManagement">
    <div class="top">
      <searchForm :searchForm.sync="listParams" @submit="getLawsuit" />
    </div>
    <div class="content">
      <div class="title">
        <el-tabs v-model="listParams.state" @tab-click="getLawsuit" style="width:100%">
          <el-tab-pane label="正常进行" name="1"></el-tab-pane>
          <el-tab-pane label="暂缓" name="2"></el-tab-pane>
          <el-tab-pane label="和解" name="3"></el-tab-pane>
          <el-tab-pane label="结案" name="4"></el-tab-pane>
          <el-tab-pane label="无法受理" name="6"></el-tab-pane>
          <el-tab-pane label="其他" name="5"></el-tab-pane>
        </el-tabs>
        <span class="btn pointer primary" style="right:86px" v-if="['ADMIN','SUPER'].includes(user.role)" @click="exportData">
          <span class="el-icon-share"></span> 导出
        </span>
        <span class="btn pointer primary" v-if="['ADMIN','SUPER'].includes(user.role)" @click="add">添加案件</span>
      </div>
      <div class="main">
        <el-table row-key="uuid" :data="list" cell-class-name="cell" header-cell-class-name="cell" style="width: 100%;font-size: 10px" size="mini" @sort-change="sortChange">
          <el-table-column label="案件编号" prop="number" width="130" sortable="number">
            <template slot-scope="scope">
              <span class="pointer primary" @click="()=>toDetail(scope.row)">{{scope.row.number ||'--'}}</span>
            </template>
          </el-table-column>

          <el-table-column label="诉讼主体" prop="companyName" width="228" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.companyName ||'--'}}</span>
                <div style="color:#999">主公司：{{scope.row.master.name ||'--'}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="对方" prop="opponent" width="100">
            <template slot-scope="scope">
              <span>{{scope.row.opponent ||'--'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="主张金额" prop="proposal" width="100">
            <template slot-scope="scope">
              <span class="primary" v-if="scope.row.proposal">¥{{scope.row.proposal}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="已交基础律师费" prop="lawyerCost.amount" width="125">
            <template slot-scope="scope">
              <span class="success" v-if="scope.row.lawyerCost.amount&&scope.row.lawyerCost.paid">¥{{scope.row.lawyerCost.amount}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="团队" prop="team" width="100">
            <template slot-scope="scope">
              <span>{{scope.row.team ||'--'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="合同版本" prop="contractEdition" width="100">
            <template slot-scope="scope">
              <span>{{scope.row.contractEdition ||'--'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="案由" prop="cause">
            <template slot-scope="scope">
              <span>{{scope.row.cause ||'--'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="渠道" prop="channel">
            <template slot-scope="scope">
              <span>{{scope.row.channel ||'--'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="审理机关" prop="inquisitor" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.inquisitor=='北海仲裁委'" class="primary">{{scope.row.inquisitor}}</span>
              <span v-else-if="scope.row.inquisitor=='中卫仲裁委'" class="success">{{scope.row.inquisitor}}</span>
              <span v-else-if="scope.row.inquisitor.length" class="error">{{scope.row.inquisitor}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>

          <el-table-column label="收费方式" prop="chargingMethod" width="100">
            <template slot-scope="scope">
              <span>{{scope.row.chargingMethod ||'--'}}</span>
            </template>
          </el-table-column>

          <el-table-column label="当前进度" prop="currentPhase" min-width="180" sortable="currentPhase" show-overflow-tooltip>
            <template slot-scope="scope">
              <currentPhaseType :item="scope.row.currentPhase" />
            </template>
          </el-table-column>
          <el-table-column label="完成时间" prop="finishedAt" min-width="180" sortable="finishedAt" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.currentPhase?.finishedAt">{{scope.row.currentPhase.finishedAt|timeFormat}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="前期费用已支付" prop="reimbursed" min-width="130" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.reimbursed" style="font-size:20px;color:#0AC258" class="el-icon-success"></span>
              <span v-else class="el-icon-warning" style="font-size:20px;color:#FAA423"></span>
            </template>
          </el-table-column>
          <el-table-column label="服务费已支付" prop="feePaid" min-width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.feePaid" style="font-size:20px;color:#0AC258" class="el-icon-success"></span>
              <span v-else class="el-icon-warning" style="font-size:20px;color:#FAA423"></span>
            </template>
          </el-table-column>

          <el-table-column label="资料链接" prop="documentUrl" width="100">
            <template slot-scope="scope">
              <a class="pointer primary el-icon-link" style="font-size:14px" :href="scope.row.documentUrl" target="_blank" rel="noopener noreferrer"></a>
            </template>
          </el-table-column>
          <el-table-column label="判/裁决结果" prop="judgmentItemList" min-width="180">
            <template slot-scope="scope">
              <div>
                <el-popover placement="right" width="250" trigger="hover" v-if="scope.row.judgmentItemList.length">
                  <el-table :data="scope.row.judgmentItemList" size="mini">
                    <el-table-column width="105" property="title" label="判/裁决结果"></el-table-column>
                    <el-table-column width="105" property="amount" label="金额"></el-table-column>
                  </el-table>
                  <div slot="reference" class="pointer">
                    <p v-for="item in scope.row.judgmentItemList.slice(0, 2)" :key="item.itemId">{{item.title}}:{{item.amount}}</p>
                  </div>
                </el-popover>

                <div v-else>--</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="110" :resizable="false" v-if="['ADMIN','SUPER'].includes(user.role)">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="()=>revision(scope.row)">编辑</el-button>
              <el-button type="text" size="mini" @click="()=>deleteLawsuit(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <c-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getLawsuit" />
    </div>

    <caseManagementForm @success="getLawsuit" ref="add" />
    <lawsuitPhaseForm @success="getLawsuit" ref="lawsuitPhaseForm" />
    <lawsuitCosForm @success="getLawsuit" ref="lawsuitCosForm" />
    <lawsuitPaymentForm @success="getLawsuit" ref="lawsuitPaymentForm" />
    <lawsuitEquityForm @success="getLawsuit" ref="lawsuitEquityForm" />
    <judgmentItemForm @success="getLawsuit" ref="judgmentItemForm" />
    <caseManagementDetail :detail="detail" :show="detailShow" @handleClose="detailShow=false" @editor="revision" @showLawsuitCosForm="showLawsuitCosForm" @showLawsuitPaymentForm="showLawsuitPaymentForm" @showJudgmentItemForm="showJudgmentItemForm" @deleteLawsuitPayment="deleteLawsuitPayment" @showLawsuitPhaseForm="showLawsuitPhaseForm" @showLawsuitEquityForm="showLawsuitEquityForm" @deleteLawsuitEquity="deleteLawsuitEquity" @deleteJudgmentItem="deleteJudgmentItem" @invoicedClick='invoicedClick' />
  </div>
</template>

<script>
import caseManagementForm from "@/views/CaseManagement/components/caseManagementForm";
import lawsuitPhaseForm from "@/views/CaseManagement/components/lawsuitPhaseForm";
import lawsuitCosForm from "@/views/CaseManagement/components/lawsuitCosForm";
import lawsuitPaymentForm from "@/views/CaseManagement/components/lawsuitPaymentForm";
import lawsuitEquityForm from "@/views/CaseManagement/components/lawsuitEquityForm";
import judgmentItemForm from "@/views/CaseManagement/components/judgmentItemForm";
import searchForm from "@/views/CaseManagement/components/searchForm"
import caseManagementDetail from "@/views/CaseManagement/components/caseManagementDetail"
import currentPhaseType from "@/views/CaseManagement/components/currentPhaseType"
import { mapState } from "vuex";
export default {
  components: {
    caseManagementForm,
    lawsuitPhaseForm,
    lawsuitCosForm,
    lawsuitPaymentForm,
    lawsuitEquityForm,
    judgmentItemForm,
    searchForm,
    caseManagementDetail,
    currentPhaseType
  },
  data () {
    return {
      list: [],
      listParams: {
        keyword: '',
        team: '',
        lawyer: '',
        contractEdition: '',
        inquisitor: '',
        chargingMethod: '',
        currentPhase: '',
        order: '',
        orderBy: '',
        state: "1"
      },
      uuid: '',
      detailShow: false
    }
  },
  mounted () {
    const { name } = this.$route.query;
    if (name) {
      this.listParams.keyword = name;
      this.$router.push({ query: {} })
    }


    this.getLawsuit();
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user
    }),
    detail () {
      if (this.uuid) {
        const index = this.list.findIndex(item => item.uuid === this.uuid);
        return this.list[index]
      } else {
        return ({})
      }
    }
  },
  filters: {
    level (list, level) {
      let arr = []
      list.forEach(item => {
        if (item.level == level) {
          arr.push(item.accountName)
        }
      })
      if (arr.length) {
        return arr.toString()
      } else {
        return '--'
      }
    }
  },
  methods: {
    sortChange ({ order, prop }) {
      if (order) {
        this.listParams.order = order;
        this.listParams.orderBy = prop
      } else {
        this.listParams.order = '';
        this.listParams.orderBy = ''
      }
      this.getLawsuit()
    },
    async getLawsuit () {
      const { current, size } = this.$refs.pagination
      const { listParams } = this
      const { status, data } = await this.$api.getLawsuit({ current, size, ...listParams });
      if (status === 200) {
        this.list = data.records
        this.$refs.pagination.createPagination(data)
      }
    },
    add () {
      this.$refs.add.show({ type: 'add' })
    },
    revision (data) {
      this.$refs.add.show({ type: 'revision', data })
    },
    showLawsuitPhaseForm (data) {
      this.$refs.lawsuitPhaseForm.show({ type: 'revision', data })
    },
    showLawsuitCosForm (data) {
      this.$refs.lawsuitCosForm.show({ type: 'revision', data })
    },
    showLawsuitPaymentForm (data) {
      this.$refs.lawsuitPaymentForm.show(data)
    },
    showLawsuitEquityForm (data) {
      this.$refs.lawsuitEquityForm.show(data)
    },
    showJudgmentItemForm (data) {
      this.$refs.judgmentItemForm.show(data)
    },
    async deleteLawsuit ({ uuid, number }) {
      try {
        await this.$confirm(`此操作将永久删除编号为《${number}》的案件，是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const { status, info } = await this.$api.deleteLawsuit({ uuid })
        if (status == 200) {
          this.$message({
            message: info,
            type: 'success'
          })
          this.getLawsuit()
        } else {
          this.$message({
            message: info,
            type: 'error'
          })
        }
      } catch (error) {
        console.log(error)
      }

    },
    toDetail (data) {
      const { uuid } = data
      this.uuid = uuid;
      this.detailShow = true;
    },
    async deleteLawsuitPayment ({ uuid, batch }) {
      try {
        await this.$confirm(`此操作将永久删除《${batch}》，是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const { status, info } = await this.$api.deleteLawsuitPayment({ uuid })
        if (status == 200) {
          this.$message({
            message: info,
            type: 'success'
          })
          this.getLawsuit()
        } else {
          this.$message({
            message: info,
            type: 'error'
          })
        }
      } catch (error) {
        console.log(error)
      }

    },
    async deleteLawsuitEquity ({ uuid }) {
      try {
        await this.$confirm(`此操作将永久删除该记录，是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const { status, info } = await this.$api.deleteLawsuitEquity({ uuid })
        if (status == 200) {
          this.$message({
            message: info,
            type: 'success'
          })
          this.getLawsuit()
        } else {
          this.$message({
            message: info,
            type: 'error'
          })
        }
      } catch (error) {
        console.log(error)
      }

    },
    async deleteJudgmentItem ({ itemId }) {
      try {
        await this.$confirm(`此操作将永久删除该记录，是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const { status, info } = await this.$api.deleteJudgmentItem({ itemId })
        if (status == 200) {
          this.$message({
            message: info,
            type: 'success'
          })
          this.getLawsuit()
        } else {
          this.$message({
            message: info,
            type: 'error'
          })
        }
      } catch (error) {
        console.log(error)
      }

    },
    //案件发票点击
    async invoicedClick (data) {
      const { status, info } = await this.$api.putChangeLawsuitCost({
        ...data,
        lawsuitUuid: data.uuid,
        invoiced: true
      })
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.getLawsuit();
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
    async exportData () {
      const { state } = this.listParams
      const { status, info } = await this.$api.postExportLawsuit({ state: +state })
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$store.commit("exportData/setBadge", true)
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.CaseManagement {
  // &/deep/.cell {
  //   padding: 0 !important;
  //   text-align: center;
  // }
  .el-icon-success {
    font-size: 18px;
    color: rgb(12, 182, 23);
  }
  .top {
    background: #fff;
    overflow: hidden;
    padding: 0 16px;
  }
  .content {
    background: #fff;
    margin-top: 24px;
    .title {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      font-weight: 400;
      font-size: 16px;
      position: relative;
      .btn {
        font-size: 14px;
        height: 24px;
        position: absolute;
        right: 16px;
        top: 0px;
        bottom: 0px;
        margin: auto;
      }
    }
    .main {
      padding: 16px;
      padding-top: 0;
    }
  }
}
</style>