/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-13 18:03:30
 * @Module: 审理机关
 */
 <template>
  <div>
    <el-autocomplete class="inline-input" style="width: 100%" :value="value" @input="change" :fetch-suggestions="querySearch" placeholder="请输入审理机关" @select="handleSelect" :clearable="clearable"></el-autocomplete>
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    value: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {
    change (res) {
      this.$emit("input", res);
    },
    querySearch (queryString, cb) {
      console.log(queryString);
      const restaurants = [
        { label: "北海仲裁委", value: "北海仲裁委" },
        { label: "中卫仲裁委", value: "中卫仲裁委" },
        { label: "南平仲裁委", value: "南平仲裁委" },
        { label: "广州仲裁委", value: "广州仲裁委" },
      ];
      var results = queryString
        ? restaurants.filter((item) => item.value.indexOf(queryString) == 0)
        : restaurants;
      console.log(results);
      cb(results);
    },
    handleSelect (item) {
      console.log(item);
    },
  },
};
</script>
 <style lang='scss' scoped>
</style>