/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-06 13:38:53
 * @Module: 辖区联络
 */
 <template>

  <div style="padding:20px;">
    <p style="color:#0062ff;">业务人员联络方式</p>
    <el-table size="mini" border :data="partners" tooltip-effect="dark" style="width: 100%;margin-top:10px;">
      <el-table-column label="账号" show-overflow-tooltip min-width="60" prop="name">
        <template slot-scope="scope">
          <span class="pointer moving">{{ scope.row.name ||"--"}}</span>
        </template>
      </el-table-column>
      <el-table-column label="联系人" show-overflow-tooltip min-width="60" prop="contact">
        <template slot-scope="scope">
          <span class="pointer moving">{{ scope.row.contact ||"--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="电话" show-overflow-tooltip min-width="60" prop="mobile">
        <template slot-scope="scope">
          <span class="pointer moving">{{ scope.row.mobile ||"--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="微信" show-overflow-tooltip min-width="60" prop="wx">
        <template slot-scope="scope">
          <span class="pointer moving">{{ scope.row.wx ||"--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="常驻地" show-overflow-tooltip min-width="60" prop="address">
        <template slot-scope="scope">
          <span class="pointer moving">{{ scope.row.address ||"--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="生日" show-overflow-tooltip min-width="60" prop="birthday">
        <template slot-scope="scope">
          <span class="pointer moving">{{ scope.row.birthday|timeFormat }}</span>
        </template>
      </el-table-column>
    </el-table>
    <p style="color:#0062ff;margin-top:20px;">法务人员联络方式</p>
    <el-table size="mini" border :data="legals" tooltip-effect="dark" style="width: 100%;margin-top:10px;">
      <el-table-column label="法务" show-overflow-tooltip min-width="60" prop="name">
        <template slot-scope="scope">
          <span class="pointer moving">{{ scope.row.name ||"--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="电话" show-overflow-tooltip min-width="60" prop="mobile">
        <template slot-scope="scope">
          <span class="pointer moving">{{ scope.row.mobile ||"--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="微信" show-overflow-tooltip min-width="60" prop="wx">
        <template slot-scope="scope">
          <span class="pointer moving">{{ scope.row.wx ||"--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="常驻地" show-overflow-tooltip min-width="60" prop="address">
        <template slot-scope="scope">
          <span class="pointer moving">{{ scope.row.address ||"--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="生日" show-overflow-tooltip min-width="60" prop="birthday">
        <template slot-scope="scope">
          <span class="pointer moving">{{ scope.row.birthday |timeFormat  }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      partners: [],
      legals: []
    };
  },
  mounted () {
    this.getAccountRelated()
  },
  methods: {
    async getAccountRelated () {
      const { status, data } = await this.$api.getAccountRelated()
      if (status === 200) {
        this.legals = data.legals;
        this.partners = data.partners;
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>
 