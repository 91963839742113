/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-25 10:31:09
 * @Module: 成单记录
 */
<template>
  <div>
    <div style="mine-height:68px;background:#fff;dispaly:flex; align-items: center;	padding: 0 16px;overflow:hidden;">
      <searchForm :searchForm.sync="listParams" @submit="getDealList" />
    </div>

    <div style="padding:16px;background:#fff;border-radius: 4px;padding-top:0px;margin-top:20px">
      <div style="display: flex;	justify-content: space-between;	align-items: center;height:56px;">
        <div>成单列表</div>
        <span v-if="['ADMIN','SUPER'].includes(user.role)">
          <el-button type="primary" size="small" @click="add">添加成单</el-button>
        </span>
      </div>
      <el-table size="mini" @sort-change="sortChange" :data="list" tooltip-effect="dark" style="width: 100%;margin-top:10px;">
        <el-table-column label="单号" prop="number" sortable="number" show-overflow-tooltip width="100">
          <template slot-scope="scope">{{ scope.row.number ||"--"}}</template>
        </el-table-column>
        <el-table-column label="成单会员" prop="promoter.name" show-overflow-tooltip width="140">
          <template slot-scope="scope">{{ scope.row.promoter?scope.row.promoter.name:"--"}}</template>
        </el-table-column>
        <el-table-column label="成单金额" prop="amount" sortable="amount" show-overflow-tooltip width="100">
          <template slot-scope="scope">{{ scope.row.amount ||"--"}}</template>
        </el-table-column>
        <el-table-column label="客户名称" prop="customerName" show-overflow-tooltip width="140">
          <template slot-scope="scope">{{ scope.row.customerName ||"--"}}</template>
        </el-table-column>
        <el-table-column label="项目名称" prop="projectName" show-overflow-tooltip width="140">
          <template slot-scope="scope">{{ scope.row.projectName ||"--"}}</template>
        </el-table-column>
        <el-table-column label="项目介绍" prop="projectIntro" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.projectIntro ||"--"}}</template>
        </el-table-column>
        <el-table-column label="备注" prop="note" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.note ||"--"}}</template>
        </el-table-column>
        <el-table-column label="添加时间" prop="createdAt" sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.createdAt">{{ scope.row.createdAt|timeFormat }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="110" :resizable="false" v-if="['ADMIN','SUPER'].includes(user.role)">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="()=>revision(scope.row)">编辑</el-button>
            <!-- <el-button type="text" size="mini" @click="()=>deleteData(scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <c-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getDealList" />
    </div>
    <dealForm ref="dealForm" @success="getDealList" />
  </div>
</template>
 <script>
import searchForm from "@/views/Deal/components/searchForm"
import dealForm from "@/views/Deal/components/dealForm"
import { mapState } from "vuex";
export default {
  components: {
    searchForm,
    dealForm
  },
  data () {
    return {
      list: [],
      promoterTreeList: [],
      listParams: {
        keyword: '',
        projectName: '',
        grade: '',
        orderBy: '',
        order: ''
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user
    })
  },
  mounted () {
    this.getDealList();
  },
  methods: {
    // 排序
    sortChange ({ order, prop }) {
      if (order) {
        this.listParams.order = order;
        this.listParams.orderBy = prop
      } else {
        this.listParams.order = '';
        this.listParams.orderBy = ''
      }
      this.getDealList()
    },
    add () {
      this.$refs.dealForm.show({ type: 'add' })
    },
    revision (data) {
      this.$refs.dealForm.show({
        type: 'revision', data: {
          ...data, promoterUuid: data.promoter ? data.promoter.uuid : null
        }
      })
    },
    async getDealList () {
      const { current, size } = this.$refs.pagination
      const { listParams } = this
      const { status, data } = await this.$api.getDealList({ current, size, ...listParams })
      if (status == 200) {
        this.list = data.records;
        this.$refs.pagination.createPagination(data)
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>