/* 
 * @Author: 曹俊杰 
 * @Date: 2024-03-13 23:18:25
 * @Module: 意向客户表单
 */
 <template>

  <el-dialog :title="type=='add'?'添加客户':type=='revision'?'修改客户':''" :visible.sync="dialogVisible" width="800px" @closed="closed">
    <div style="padding:20px">
      <el-form size="mini" label-position="left" :rules="rules" label-width="80px" :model="form" ref="form">
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="客户名称" prop="companyName">
              <el-input v-model="form.companyName" placeholder="意向客户的名称（公司填公司，个人填个人）"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号码" prop="phone">
              <el-input v-model="form.phone" placeholder="意向客户的手机号码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="微信名称" prop="weixinName">
              <el-input v-model="form.weixinName" placeholder="意向客户的微信名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="微信号码" prop="weixin">
              <el-input v-model="form.weixin" placeholder="意向客户的微信号码"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="基本情况" prop="note">
              <el-input v-model="form.note" type="textarea" :autosize="{ minRows: 4, maxRows: 12 }" placeholder="请输入意向客户的基本情况">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="图片" prop="image">
              <uploadImage v-model="form.image" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
 <script>


import uploadImage from "@/components/uploadImage/uploadImage"
export default {
  components: {
    uploadImage
  },
  data () {
    const phoneValidator = (_, value, callback) => {
      if (this.$utils.test.mobile(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的手机号'));
      }
    }
    return {
      type: 'add',
      dialogVisible: false,
      form: {
        companyName: '',
        phone: '',
        weixin: '',
        note: '',
        image: '',
				weixinName:''
      },
      //rules前端验证
      rules: {
        companyName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        phone: [
          { validator: phoneValidator, trigger: 'blur' }
        ],
      },
    };
  },
  mounted () { },
  methods: {
    show ({ type = 'add', data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
      }
      this.type = type
      this.dialogVisible = true
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.putAccount()
        } else {
          return false
        }
      })
    },
    closed () {
      this.form = {
        companyName: '',
        phone: '',
        weixin: '',
        note: '',
        image: '',
				weixinName:''
      }
    },
    async putAccount () {
      const api = {
        revision: this.$api.putChangeAccount,
        add: this.$api.postClient
      }
      const { info, status } = await api[this.type](this.form);
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$emit('success', this.type)
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
    uploadDialogShow () {
      this.$refs.uploadDialog.show()
    },
    uploadDialogSuccess (arr) {
      this.form.image = arr.toString()
    },
  },
};
 </script>
 <style lang='scss' scoped>
</style>
