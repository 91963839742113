/* 
 * @Author: 曹俊杰 
 * @Date: 2022-03-18 17:00:50
 * @Module: 分页
 */
 <template>
  <div class="pagination">
    <el-pagination :small="small" hide-on-single-page @current-change="currentChange" @size-change="sizeChange" :page-count="pages" :current-page.sync="current" :page-size.sync="size" :layout="layout" :total="total">
    </el-pagination>
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    layout: {
      type: String,
      default: 'prev, pager, next'
    },
    small: {
      type: Boolean,
      default: false
    }

  },
  data () {
    return {
      size: 10,
      pages: 0,
      current: 1,
      total: 0
    };
  },
  mounted () { },
  methods: {
    sizeChange (size) {
      const { pages, current, total } = this
      if (current == 1) {
        this.$emit('change', {
          size,
          pages,
          current,
          total
        })
      }
    },
    currentChange (current) {
      const { pages, size, total } = this
      this.$emit('change', {
        size,
        pages,
        current,
        total
      })
    },
    /**
     * @param {Number} size 一页几条
     * @param {Number} pages 总共多少页
     * @param {Number} current 当前第几页
     * @param {Number} total 总共几条
     */
    createPagination ({ size, pages, current, total }) {
      this.size = size;
      this.pages = pages;
      this.current = current;
      this.total = total;
    }
  },
};
 </script>
 <style lang='scss' scoped>
.pagination {
  // position: absolute;
  // bottom: 5px;
  margin: 10px 0;
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
}
</style>