/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-28 14:57:12
 * @Module: 匹配成单
 */
  <template>
  <el-select style="width:100%;" clearable :value="value" @change="change" filterable :placeholder="placeholder" :loading="loading">
    <el-option v-for="item in options" :key="item.uuid" :label="item.customerName" :value="item.uuid">
      <span style="float: left">{{ item.number }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.projectName }}</span>
    </el-option>
  </el-select>
</template>
 <script>
export default {
  components: {},
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择匹配成单'
    }
  },
  data () {
    return {
      options: [],
      loading: false
    };
  },
  mounted () {
    this.getDealCandidateList()
  },
  methods: {
    async getDealCandidateList () {
      const { status, data } = await this.$api.getDealCandidateList()
      if (status == 200) {
        this.options = data;
      }
    },
    change (value) {
      this.$emit('input', value)
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>