/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-13 15:20:14
 * @Module: 案件进展表单
 */

 <template>

  <el-dialog :title="type=='add'?'添加案件进展':type=='revision'?'修改案件进展':''" :visible.sync="dialogVisible" width="800px" @closed="closed">
    <div style="padding:20px">
      <el-form size="mini" label-position="top" label-width="120px" :model="form" ref="form">
        <el-row :gutter="30">
          <el-col :span="24">
            <el-form-item label="程序" prop="content">
              <el-input v-model="form.content" type="textarea" :autosize="{minRows: 4, maxRows: 12}" placeholder="请输入程序名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="note">
              <el-input v-model="form.note" type="textarea" :autosize="{minRows: 4, maxRows: 12}" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否完成" prop="finished">
              <el-select v-model="form.finished" placeholder="请选择" style="width:100%;">
                <el-option label="已完成" :value="true" />
                <el-option label="未完成" :value="false" />
              </el-select>
              <!-- <el-radio-group v-model="form.finished">
            <el-radio :label="true">完成</el-radio>
            <el-radio :label="false">未完成</el-radio>
          </el-radio-group> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="完成时间" prop="finishedAt">
              <el-date-picker value-format="timestamp" style="width:100%;" v-model="form.finishedAt" type="date" placeholder="选择完成时间" />
            </el-form-item>
          </el-col>

        </el-row>

      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
 <script>
export default {

  data () {

    return {
      type: 'add',
      dialogVisible: false,
      form: {
        lawsuitUuid: '',
        content: '',
        finished: false,
        finishedAt: '',
        note: ''
      }
    };
  },
  mounted () { },
  methods: {
    show ({ type = 'add', data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
      }
      this.type = type
      this.dialogVisible = true
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.postLawsuitPhase()
        } else {
          return false
        }
      })
    },
    close () {
      // this.form = { ...this.form, ...this.oldform };
      this.dialogVisible = false
    },
    closed () {
      this.form = {
        lawsuitUuid: '',
        content: '',
        finished: false,
        finishedAt: '',
        note: ''
      }
    },
    async postLawsuitPhase () {
      const api = {
        revision: {
          func: this.$api.putChangeLawsuitPhase,
          params: {
            ...this.form,
            lawsuitUuid: this.form.uuid
          }
        },
        add: {
          func: this.$api.postLawsuitPhase,
          params: {
            ...this.form
          }
        }
      }

      const { status, data, info } = await api[this.type].func(api[this.type].params)
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$emit('success', this.type)
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
</style>
