/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-07 19:24:04
 * @Module:外部案件详情 
 */
 <template>
  <div class="outerCaseManagementDetail">
    <el-drawer :visible="show" direction="rtl" :before-close="handleClose" :show-close="false" size="800px">
      <el-tabs v-model="activeName" slot="title">
        <el-tab-pane name="first">
          <div slot="label" class="label">
            案件信息
          </div>
        </el-tab-pane>
        <el-tab-pane name="second">
          <div slot="label" class="label">案件费用</div>
        </el-tab-pane>
        <el-tab-pane name="third">
          <div slot="label" class="label">案件进度</div>
        </el-tab-pane>
      </el-tabs>
      <div v-if="show">
        <div class="box1" v-show="activeName=='first'">
          <div class="title">
            <span>案件编号：{{detail.number}}</span>
            <el-button type="primary" size="small" @click="editor">编辑修改</el-button>
          </div>

          <div style="padding:0 20px;">
            <div class="tit">基础信息</div>
            <el-descriptions labelClassName="contentLabel" contentClassName="contentValue" direction="vertical" size="small" :column="2" style="margin-top:24px;padding:14px;padding-top:0;">
              <el-descriptions-item label="诉讼主体">{{detail.companyName||'--'}}</el-descriptions-item>
              <el-descriptions-item label="对方">{{detail.opponent||'--'}}</el-descriptions-item>
              <el-descriptions-item label="主公司">{{detail.master.name||'--'}}</el-descriptions-item>
              <el-descriptions-item label="主张金额"><span class="primary">{{detail.proposal||'--'}}</span></el-descriptions-item>
              <el-descriptions-item label="公司编号">{{detail.master.number||'--'}}</el-descriptions-item>
              <el-descriptions-item label="团队">{{detail.team||'--'}}</el-descriptions-item>
              <el-descriptions-item label="案由">{{detail.cause||'--'}}</el-descriptions-item>
              <el-descriptions-item label="承办律师">{{detail.lawyer||'--'}}</el-descriptions-item>
              <el-descriptions-item label="审理机关">
                <span v-if="detail.inquisitor=='北海仲裁委'" class="primary">{{detail.inquisitor}}</span>
                <span v-else-if="detail.inquisitor=='中卫仲裁委'" class="success">{{detail.inquisitor}}</span>
                <span v-else-if="detail.inquisitor.length" class="error">{{detail.inquisitor}}</span>
                <span v-else>--</span>
              </el-descriptions-item>
            </el-descriptions>

            <div class="tit">案件资料</div>
            <el-descriptions labelClassName="contentLabel" contentClassName="contentValue" direction="vertical" size="small" :column="2" style="margin-top:24px;padding:14px;padding-top:0;">
              <el-descriptions-item label="资料链接">
                <a class="moving" :href="detail.documentUrl" v-if="user.role==='ADMIN'||user.role==='LEGAL'||user.role==='SUPER'" target="_blank" rel="noopener noreferrer">{{detail.documentUrl ||'--'}}</a>
                <span v-else>--</span>
              </el-descriptions-item>
              <el-descriptions-item label="合同版本">{{detail.contractEdition||'--'}}</el-descriptions-item>
              <el-descriptions-item label="收费方式">
                <span class="error" style="font-weight:700;" v-if="detail.chargingMethod">{{detail.chargingMethod}}</span>
                <span v-else>--</span>
              </el-descriptions-item>
              <el-descriptions-item label="当前进度">

                <currentPhaseType :item="detail.currentPhase" />
              </el-descriptions-item>
              <!-- <el-descriptions-item label="服务费">
                <span v-if="detail.serviceFee" class="primary">{{detail.serviceFee}}</span>
                <span v-else>--</span>
              </el-descriptions-item> -->
              <el-descriptions-item label="法务">
                <span v-if="detail.master.legalName" class="primary">{{detail.master.legalName}}</span>
                <span v-else>--</span>
              </el-descriptions-item>
              <!-- <el-descriptions-item label="实际权益金额">
                <span v-if="detail.actualAmount" class="primary">{{detail.actualAmount}}</span>
                <span v-else>--</span>
              </el-descriptions-item> -->
              <el-descriptions-item label="渠道">{{detail.channel||'--'}}</el-descriptions-item>
              <el-descriptions-item label="最近编辑">{{detail.updatedAt |timeFormat}}</el-descriptions-item>
            </el-descriptions>
          </div>

        </div>
        <div class="box2" v-show="activeName=='second'">
          <div class="title">前期费用</div>
          <div class="box2-main">
            <el-table :data="detail.lawsuitCostList" style="width: 100%" size="mini">
              <el-table-column label="项目" prop="item">
                <template slot-scope="scope">
                  <span>{{scope.row.item ||'--'}}</span>
                </template>
              </el-table-column>
              <el-table-column label="金额(元)" prop="amount">
                <template slot-scope="scope">
                  <span>{{scope.row.amount ||'--'}}</span>
                </template>
              </el-table-column>
              <el-table-column label="谁付" prop="payer">
                <template slot-scope="scope">
                  <span>{{scope.row.payer ||'--'}}</span>
                </template>
              </el-table-column>
              <el-table-column label="付费进度" prop="paid">
                <template slot-scope="scope">
                  <span v-if="scope.row.paid" style="font-size:20px;color:#0AC258" class="el-icon-success"></span>
                  <span v-else class="el-icon-warning" style="font-size:20px;color:#FAA423"></span>
                </template>
              </el-table-column>
              <el-table-column label="需要报销(元)" prop="reimburseAmount">
                <template slot-scope="scope">
                  <span>{{scope.row.reimburseAmount||0 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="报销进度" prop="reimbursed">
                <template slot-scope="scope">
                  <span v-if="scope.row.reimbursed" style="font-size:20px;color:#0AC258" class="el-icon-success"></span>
                  <span v-else class="el-icon-warning" style="font-size:20px;color:#FAA423"></span>
                </template>
              </el-table-column>
              <el-table-column label="发票" prop="invoiced" width="50" >
                <template slot-scope="scope">
                  <span v-if="scope.row.invoiced" style="font-size:20px;color:#0AC258" class="el-icon-success"></span>
                  <el-popconfirm title="确定发票已开具吗？" v-else @confirm="()=>invoicedClick(scope.row)">
                     <span slot="reference"  class="el-icon-warning" style="font-size:20px;color:#FAA423"></span>
                  </el-popconfirm>
                </template>
              </el-table-column>
              <el-table-column label="备注" prop="note">
                <template slot-scope="scope">
                  <span>{{scope.row.note ||'--'}}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="60" :resizable="false">
                <template slot-scope="scope">
                  <el-button type="text" size="mini" @click="()=>showLawsuitCosForm(scope.row)">编辑</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>

        </div>
        <div class="box3" v-show="activeName=='third'">
          <el-steps direction="vertical" space="40px">
            <el-step v-for="(item) in detail.lawsuitPhaseList" :key="item.uuid" :status="item.finished?'finish':'wait'" :icon="item.finished?'el-icon-circle-check':'el-icon-warning-outline'">
              <template>

                <div slot="description">
                  <div class="title">
                    <span> <span v-if="item.number < (detail.lawsuitPhaseList.length - 3)">{{item.number}}、</span>

                      <currentPhaseType :item="item" :showNumber="false" />

                      <!-- {{item.content}} -->
                    </span>
                    <i class="el-icon-edit"  @click="()=>showLawsuitPhaseForm(item)"></i>
                  </div>
                  <div class="time">
                    <span v-if="item.finishedAt&&item.finished">{{ item.finishedAt|timeFormat }}</span>
                  </div>
                  <div class="note">
                    {{item.note}}
                  </div>
                </div>
              </template>
            </el-step>
          </el-steps>
        </div>
      </div>

    </el-drawer>
  </div>
</template>
 <script>
import { mapState } from "vuex";
import currentPhaseType from "@/views/CaseManagement/components/currentPhaseType"
export default {
  components: {
    currentPhaseType
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      activeName: 'first'
    };
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user
    })
  },
  mounted () { },
  methods: {
    handleClose () {
      this.$emit('handleClose', false)
    },
    editor () {
      this.$emit('editor', this.detail)
    },
    // 编辑前期费用
    showLawsuitCosForm (data) {
      this.$emit('showLawsuitCosForm', data)
    },
    // 添加/编辑 案件服务费
    showLawsuitPaymentForm (data) {
      this.$emit('showLawsuitPaymentForm', data)
    },
    // 删除案件服务费
    deleteLawsuitPayment (data) {
      this.$emit('deleteLawsuitPayment', data)
    },
    // 编辑收费进度
    showLawsuitPhaseForm (data) {
      this.$emit('showLawsuitPhaseForm', data)
    },
		// 开发票事件
		invoicedClick(data){
			this.$emit('invoicedClick', data)
		}
  },
};
 </script>
 <style lang='scss' scoped>
.outerCaseManagementDetail {
  &/deep/.el-drawer__header {
    padding: 0;
  }
  .label {
    width: 100px;
    text-align: center;
  }
  .maim {
    padding: 0 20px;
  }
  .tit {
    line-height: 40px;
    background: #f7f8f9;
    padding: 0 14px;
    font-size: 14px;
    color: #262626;
  }
  .box1 {
    .title {
      height: 56px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      font-size: 22px;
      line-height: 33px;
      color: #262626;
      .btn {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #0d72ff;
      }
    }
    &/deep/.contentLabel {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #262626;
    }
    &/deep/.contentValue {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #666666;
    }
  }
  .box2 {
    .title {
      height: 56px;
      padding: 0 20px;
      font-weight: 400;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-main {
      padding: 0 20px;
    }
  }
  .box3 {
    padding: 0 44px;
    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */

      /* 字体/262626 */

      color: #262626;
      i {
        margin-left: 10px;
        color: #999999;
      }
      i:hover {
        color: #0d72ff;
        cursor: pointer;
      }
    }
    .time {
      font-size: 14px;
      line-height: 21px;
      margin-top: 8px;
      /* identical to box height */

      /* 字体/999999 */

      color: #999999;
    }
    .note {
      margin-bottom: 40px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */

      /* 字体/666666 */

      color: #666666;
      margin-top: 8px;
    }
  }
}
</style>