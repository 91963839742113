/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-11 18:55:06
 * @Module: 法务表单
 */
 <template>
  <el-select style="width:100%;" filterable value-key="uuid" :value="value" placeholder="请选择法务" @change="change" :clearable="clearable">
    <el-option v-for="item in accountLegalList" :key="item.uuid" :label="item.name" :value="item.uuid">

    </el-option>
  </el-select>
</template>
 <script>
export default {
  components: {},
  props: {
    value: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      accountLegalList: []
    };
  },
  mounted () {
    this.getAccountLegalList();
  },
  methods: {
    async getAccountLegalList () {
      const { status, data } = await this.$api.getAccountLegalList()
      if (status === 200) {
        this.accountLegalList = data
      }
    },
    change (res) {
      this.$emit('input', res)
    },

  },
};
 </script>
 <style lang='scss' scoped>
</style>