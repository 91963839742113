/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-23 10:56:47
 * @Module: 案件进度样式
 */
 <template>
  <span>

    <span :class="'type'+data.number" class="text" v-if="data.number"><span v-if="showNumber">{{data.number+'、'}}</span> {{data.content}}</span>
    <span v-else>--</span>
  </span>
</template>
 <script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: () => ({
        content: '',
        number: null
      })
    },
    showNumber: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {},
  computed: {
    data () {
      return this.item || {
        content: '',
        number: null
      }
    }
  }
};
 </script>
 <style lang='scss' scoped>
.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  /* 字体/262626 */

  color: #262626;
  padding: 0 8px;
  background: #fbebd5;
}
.type1,
.type2,
.type3 {
  background: #fbebd5;
}
.type4,
.type5,
.type6 {
  background: #e3eafd;
}
.type7,
.type8,
.type9 {
  background: #def2fc;
}
.type10,
.type11,
.type12 {
  background: #f8f1d4;
}
.type13,
.type14,
.type15 {
  background: #dbf5f2;
}
.type16,
.type17,
.type18 {
  background: #f9e3e2;
}
.type19,
.type20,
.type21 {
  background: #eae2fc;
}
.type22,
.type23,
.type24 {
  background: #ddf4d7;
}
.type25,
.type26,
.type27 {
  background: #fbe3ff;
}
</style>