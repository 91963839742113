/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-10 17:16:54
 * @Module: 图片缩略图
 */
 <template>
  <el-image v-if="imageUrls" :preview-src-list="imageUrls|imageUrls" :src="require('@/static/img/icon_image.png')" style="width:20px;height:20px;"></el-image>
</template>
 <script>
export default {
  components: {},
  props: {
    imageUrls: {
      type: String,
      default: ''
    }
  },
  data () {
    return {};
  },
  filters: {
    imageUrls (str) {
      return str.split(',').map(item => "https://oss.mcn-open.com/" + item)
    }
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
</style>