/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-12 11:39:12
 * @Module: 签约人
 */
 <template>
  <el-select style="width:100%;" filterable value-key="uuid" :value="value" placeholder="请选择账号" clearable @change="change">
    <el-option v-for="item in accountShareList" :key="item.uuid" :label="item.name" :value="item.uuid">
    </el-option>
  </el-select>
</template>
 <script>
export default {
  components: {},
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      accountShareList: []
    };
  },

  mounted () {
    this.getAccountShareList()
  },
  methods: {
    async getAccountShareList () {
      const { status, data } = await this.$api.getAccountShareList()
      if (status === 200) {
        this.accountShareList = data;
      }
    },
    change (value) {
      this.$emit('input', value)
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>