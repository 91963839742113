/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-23 13:41:15
 * @Module: 筛选表单
 */
 
 <template>
  <el-form :inline="true" :model="searchForm" size="mini" style="margin-top:20px;">
    <el-form-item label="关键词">
      <el-input v-model="searchForm.keyword" @keyup.enter.native="onSubmit" style="width:300px;" placeholder="请输入要搜索的客户名称、微信号码、手机号码" clearable></el-input>
    </el-form-item>
   
    <el-form-item>
      <el-button type="primary" @click="onSubmit">查询</el-button>
    </el-form-item>
  </el-form>

</template>
 <script>

export default {
  components: {
  },
  props: {
    searchForm: {
      type: Object,
      default: () => ({
        keyword: ''
      })
    }
  },
  data () {
    return {
    };
  },
  mounted () {

  },
  methods: {
    onSubmit () {
      this.$emit('submit')
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>
