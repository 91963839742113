/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-13 18:03:30
 * @Module: 合同版本
 */
 <template>
  <div>
    <el-autocomplete
      class="inline-input"
      style="width: 100%"
      :value="value"
      @input="change"
      :fetch-suggestions="querySearch"
      placeholder="请输入合同版本"
      :clearable="clearable"
      @select="handleSelect"
    ></el-autocomplete>
  </div>
</template>
 <script>
export default {
  components: {},
  props: {
    value: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    change(res) {
      this.$emit("input", res);
    },
    querySearch(queryString, cb) {
      const restaurants = [
        { label: "中能版本", value: "中能版本" },
        { label: "其他版本", value: "其他版本" },
      ];
      var results = queryString
        ? restaurants.filter((item) => item.value.indexOf(queryString) == 0)
        : restaurants;
      console.log(results);
      cb(results);
    },
    handleSelect(item) {
      console.log(item);
    },
  },
};
</script>
 <style lang='scss' scoped>
</style>