import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './plugins/element.js'
import store from './store'
import utils from '@/utils/utils.js'
import router from './router'
import './assets/style/iconfont.css'
import CPagination from '@/components/CPagination'
Vue.config.productionTip = false

const app = new Vue({
	store,
	router,
	render: h => h(App)
})
Vue.component('CPagination', CPagination)
Vue.prototype.$oss = "https://oss.mcn-open.com/"
Vue.use(utils, app)
app.$mount('#app')
