/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-06 11:29:22
 * @Module: 默认layouts
 */
 <template>
  <el-container style="height: 100vh">
    <el-aside class="menu" width="auto">
      <Menu />
    </el-aside>
    <el-container style="background-color: #f7f8f9">
      <el-header style="padding: 0px">
        <Header />
      </el-header>
      <el-main class="main" style="padding: 24px;padding-bottom:0px;">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" style="min-height: calc(100vh - 60px - 60px - 24px )"></router-view>
        </keep-alive>
        <!-- <router-view ></router-view> -->
        <router-view v-if="!$route.meta.keepAlive" :style="
            $route.meta.transparency
              ? ''
              : 'background:#fff;box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.0600);'
          " style="min-height: calc(100vh - 60px - 60px - 24px )" />
        <el-footer style="padding: 0px">
          <Footer />
        </el-footer>
      </el-main>
    </el-container>
  </el-container>
</template>
 <script>
import Menu from "@/components/layouts/Menu";
import Footer from "@/components/layouts/Footer";
import Header from "@/components/layouts/Header";
export default {
  components: {
    Menu,
    Footer,
    Header,
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
</script>
 <style lang='scss' >
.menu {
  background: #fff;
  p {
    line-height: 60px;
    font-size: 20px;
    color: rgb(84, 92, 100);
    text-align: center;
  }
}
.main {
  padding-bottom: 0px;
}
</style>
