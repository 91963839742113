/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-06 13:38:13
 * @Module: 客户检索
 */
 <template>

  <div>
    <el-alert title="输入公司全称，可在全国范围内进行检索（包括关联公司）" :closable="false" type="warning">
    </el-alert>
    <div style="padding:20px;padding-top:10px;">
      <el-input placeholder="请输入公司全称" v-model="search" style="width:40%;" size="mini" @keyup.enter.native="getCompanySearch">
        <el-button slot="append" icon="el-icon-search" @click="getCompanySearch"></el-button>
      </el-input>
      <el-table empty-text="输入公司全称，可在全国范围内进行检索（包括关联公司）" border size="mini" style="width: 100%;margin-top:10px;" :data="list">
        <el-table-column label="公司全称" show-overflow-tooltip min-width="180" prop="name">
          <template slot-scope="scope">
            <span>{{ scope.row.name||'--' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="关联公司" show-overflow-tooltip min-width="180" prop="related">
          <template slot-scope="scope">
            <span>{{ scope.row.related||'--' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="谁签约的" show-overflow-tooltip min-width="180" prop="signer">
          <template slot-scope="scope">
            <span>{{ scope.row.signer.name||'--' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="签约日期" show-overflow-tooltip min-width="180" prop="signedAt">
          <template slot-scope="scope">
            <span>{{ scope.row.signedAt|timeFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column label="同事联系电话" show-overflow-tooltip min-width="180" prop="mobile">
          <template slot-scope="scope">
            <span>{{ scope.row.signer.mobile||'--' }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      search: '',
      list: []
    };
  },
  mounted () { },
  methods: {
    async getCompanySearch () {
      const { search: companyName } = this
      const { status, data } = await this.$api.getCompanySearch({ companyName });
      console.log(data)
      if (status == 200) {
        this.list = [data]
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>