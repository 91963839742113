/* 
 * @Author: 曹俊杰 
 * @Date: 2023-04-22 22:11:44
 * @Module: 判决项表单
 */
 <template>
  <el-dialog :title="type=='add'?'添加判/裁决结果':type=='revision'?'修改判/裁决结果':''" :visible.sync="dialogVisible" width="800px" @closed="closed">
    <div style="padding:20px;">
      <el-form size="mini" label-position="top" :model="form" ref="form">
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="判/裁决结果标题" prop="title">
              <el-input v-model="form.title" placeholder="请输入判/裁决结果标题"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="金额" prop="amount">
              <el-input v-model.number="form.amount" placeholder="请输入金额"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
 <script>
export default {

  data () {

    return {
      type: 'add',
      dialogVisible: false,
      form: {
        title: '',
        amount: '',
      }
    };
  },
  mounted () { },
  methods: {
    show ({ type = 'add', data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
				console.log(data)
      } else if (type == "add") {
        this.lawsuitUuid = data.uuid
      }
      this.type = type
      this.dialogVisible = true
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.postJudgmentItem()
        } else {
          return false
        }
      })
    },
    close () {
      this.dialogVisible = false
    },
    closed () {
      this.form = {
        item: '',
        amount: '',
      }
    },
    async postJudgmentItem () {
      const api = {
        revision: {
          func: this.$api.putChangeJudgmentItem,
          params: {
            ...this.form
          }
        },
        add: {
          func: this.$api.postJudgmentItem,
          params: {
            ...this.form,
            lawsuitUuid: this.lawsuitUuid
          }
        }
      }

      const { status, data, info } = await api[this.type].func(api[this.type].params)
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$emit('success', this.type)
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
</style>