/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-08 13:36:54
 * @Module: 创建外部公司
 */
<template>
  <div class="add">
    <el-dialog :append-to-body="true" :title="type=='revision'?'编辑公司':type=='add'?'创建公司':''" :visible.sync="dialogVisible" :size="670">
      <div style="overflow-y: scroll;height:500px;">

        <el-form style="padding:0 20px; " size="mini" label-position="top" label-width="120px" :model="form" ref="form" :rules="rules">
          <div style="line-height: 40px;font-size: 14px;padding:0 14px;background: #F7F8F9;margin:10px 0;">公司信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="公司全称" prop="name">
                <el-input v-model="form.name" placeholder="请输入公司全称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="对接法务" prop="legalUuid">
                <legal v-model="form.legalUuid" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="对接群名" prop="groupName">
                <el-input v-model="form.groupName" placeholder="请输入对接群名"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="对接群名" prop="groupName">
                <el-input v-model="form.groupName" placeholder="请输入对接群名"></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item label="团队" prop="team">
                <team v-model="form.team" team="仲达,仲管,速裁,不倒翁" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="流水规模" prop="scaleId">
                <scale v-model="form.scaleId" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="主营业务" prop="businessIds">
                <business v-model="form.businessIds" />
              </el-form-item>
            </el-col>

            <!-- <el-col :span="12">
              <el-form-item label="合同到期时间" prop="expiredAt">
                <el-date-picker style="width:100%;" value-format="timestamp" v-model="form.expiredAt" type="date" placeholder="选择合同到期时间" />
              </el-form-item>
            </el-col> -->

            <el-col :span="12">
              <el-form-item label="主做平台" prop="platformIds">
                <platform v-model="form.platformIds" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="税咨" prop="tax">
                <el-select v-model="form.tax" style="width:100%;">
                  <el-option label="开" :value="true">
                  </el-option>
                  <el-option label="关" :value="false">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="联系方式" prop="contacts">
                <el-input type="textarea" :autosize="{minRows: 4, maxRows: 12}" v-model="form.contacts" placeholder="请输入联系方式"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="客户基本情况" prop="situation">
                <el-input type="textarea" :autosize="{minRows: 4, maxRows: 12}" v-model="form.situation" placeholder="请输入客户基本情况"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="note">
                <el-input type="textarea" :autosize="{minRows: 4, maxRows: 12}" v-model="form.note" placeholder="请输入备注"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="合同文件" prop="contractUrls">
                <contractUrls v-model="form.contractUrls" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="营业执照" prop="licenseUrl">
                <imageUrls :limit="1" :multiple="false" v-model="form.licenseUrl" />
              </el-form-item>
            </el-col>
          </el-row>

          <div style="line-height: 40px;font-size: 14px;padding:0 14px;background: #F7F8F9;margin:10px 0;">关联公司</div>
          <el-button type="text" v-show="form.relatedCompanys.length==0" @click="addRelatedCompanys">添加关联公司</el-button>
          <el-row :gutter="20" align="middle">
            <el-col :span="22">
              <el-form-item v-for="(item, index) in form.relatedCompanys" label-width="0" :key="item.key" :prop="'relatedCompanys.' + index + '.name'" :rules="{
                  required: true, message: '关联公司不能为空', trigger: 'blur'
                }">
                <el-input v-model="item.name" placeholder="输入关联公司"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <div style="padding-top:8px;height:48px;" v-for="item in form.relatedCompanys" :key="item.uuid">
                <i class="el-icon-remove-outline" @click="removeRelatedCompanys(item)"></i>
                <i class="el-icon-circle-plus-outline" @click="addRelatedCompanys"></i>
              </div>
            </el-col>
          </el-row>
          <!-- <div style="line-height: 40px;font-size: 14px;padding:0 14px;background: #F7F8F9;margin:10px 0;">客户联络人</div>
          <el-button type="text" v-show="form.contacts.length==0" @click="addContacts">添加客户联络人</el-button>
          <el-row :gutter="20" align="middle">
            <el-col :span="6">
              <el-form-item v-for="(item, index) in form.contacts" label-width="0" :key="item.uuid" :prop="'contacts.' + index + '.name'">
                <el-input v-model="item.name" placeholder="请输入姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item v-for="(item, index) in form.contacts" label-width="0" :key="item.uuid" :prop="'contacts.' + index + '.phone'">
                <el-input v-model="item.phone" placeholder="请输入电话"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item v-for="(item, index) in form.contacts" label-width="0" :key="item.uuid" :prop="'contacts.' + index + '.wx'">
                <el-input v-model="item.wx" placeholder="请输入微信"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item v-for="(item, index) in form.contacts" label-width="0" :key="item.uuid" :prop="'contacts.' + index + '.position'">

                <el-select v-model="item.position" placeholder="请选择职务" filterable allow-create>
                  <el-option key="法人" label="法人" value="法人" />
                  <el-option key="负责人" label="负责人" value="负责人" />
                  <el-option key="股东" label="股东" value="股东" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <div style="padding-top:8px;height:48px;" v-for="item in form.contacts" :key="item.uuid">
                <i class="el-icon-remove-outline" @click="removeContacts(item)"></i>
                <i class="el-icon-circle-plus-outline" @click="addContacts"></i>
              </div>
            </el-col>
          </el-row> -->
        </el-form>
        <div style="height:80px;width:100%;display:flex;justify-content: flex-end;align-items: center;padding:0 24px;">
          <el-button type="primary" size="small" @click="submit" style="float:right">提交</el-button>

        </div>
      </div>

    </el-dialog>
  </div>
</template>
<script>
import contractUrls from "@/views/CustomerManagement/components/form/contractUrls"
import imageUrls from "@/views/CustomerManagement/components/form/imageUrls"
import team from "@/views/CaseManagement/components/form/team"
// import legal from "@/views/CustomerManagement/components/form/legal"
// import areaCode from "@/views/CustomerManagement/components/form/areaCode"
// import accountShare from "@/views/CustomerManagement/components/form/accountShare"
// import related from "@/views/CustomerManagement/components/form/related"
import business from "@/views/CustomerManagement/components/form/business"
import platform from "@/views/CustomerManagement/components/form/platform"
// import signState from "@/views/CustomerManagement/components/form/signState"
import scale from "@/views/CustomerManagement/components/form/scale"
import legal from "@/views/CustomerManagement/components/form/legal"
export default {
  components: {
    contractUrls,
    imageUrls,
    legal,
    // areaCode,
    // accountShare,
    // related,
    business,
    platform,
    scale,
    team
    // signState
  },
  data () {
    const phoneValidator = (_, value, callback) => {
      if (this.$utils.test.mobile(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的手机号'));
      }
    }
    return {
      // scaleList: [],
      dialogVisible: false,
      form: {
        name: null,
        scaleId: null,
        businessIds: [],
        team: '',
        note: '',
        legalUuid: null,

        groupName: null,
        situation: null,
        platformIds: [],
        contractUrls: null,
        licenseUrl: null,
        relatedCompanys: [],
        contacts: "",
        tax: false
      },
      //rules前端验证
      rules: {

        name: [
          { required: true, message: '公司全称不能为空', trigger: 'blur' },
        ],
      },
      type: "add"
    };
  },
  mounted () {
    // this.getScaleList();
  },
  methods: {
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.putCompany()
        } else {
          return false
        }
      })
    },
    show ({ type = 'add', data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
      }
      this.type = type
      this.dialogVisible = true
    },

    addContacts () {
      this.form.contacts.push({
        name: '',
        phone: '',
        wx: '',
        position: '',
        uuid: Date.now()
      });
    },
    addRelatedCompanys () {
      this.form.relatedCompanys.push({
        name: '',
        uuid: Date.now()
      });
    },

    removeContacts (item) {
      var index = this.form.contacts.indexOf(item)
      // if (index > 0) {
      this.form.contacts.splice(index, 1)
      // }
    },
    removeRelatedCompanys (item) {
      var index = this.form.relatedCompanys.indexOf(item)
      // if (index > 0) {
      this.form.relatedCompanys.splice(index, 1)
      // }
    },
    async putCompany () {
      // let { related } = this.form
      console.log(this.form)
      const api = {
        revision: {
          func: this.$api.putChangeOuterCompany,
          params: {
            ...this.form,
            // uuid: this.$route.params.uuid,
            // related: related.toString()
          }
        },
        add: {
          func: this.$api.postOuterCompany,
          params: {
            ...this.form,
            // related: related.toString()
          }
        }
      }

      const { status, data, info } = await api[this.type].func(api[this.type].params)
      if (status === 200) {
        this.$message({
          type: "success",
          message: info
        })
        this.dialogVisible = false;
        this.$emit('success')
      } else {
        this.$message({
          type: "error",
          message: info
        })
      }
    },
    // async getScaleList () {
    //   const { status, data } = await this.$api.getScaleList();
    //   if (status === 200) {
    //     this.scaleList = data;
    //   }
    // },
    closed () {
      this.form = {
        name: null,
        scaleId: null,
        businessIds: [],
        legalUuid: null,
        groupName: null,
        situation: null,
        platformIds: [],
        contractUrls: null,
        licenseUrl: null,
        relatedCompanys: [],
        contacts: "",
      }
    },
  },
};
</script>
<style lang='scss' scoped>
/deep/ {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    height: auto;
    -webkit-appearance: none;
  }
}
</style>
