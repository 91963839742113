/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-28 11:23:48
 * @Module: 分佣表单
 */
  <template>
  <el-dialog :title="type=='add'?'添加分佣':type=='revision'?'修改分佣':''" :visible.sync="dialogVisible" width="40%" @closed="closed">
    <div style="padding:20px;">
      <el-form size="mini" label-position="top" label-width="120px" :model="form" ref="form">
        <el-row :gutter="30">

          <el-col :span="12">
            <el-form-item label="会员" prop="promoterUuid" :rules="{ required: true, message: '会员不能为空'}">
              <inviter v-model="form.promoterUuid" placeholder="请选择会员" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="动帐类型" prop="type" :rules="{ required: true, message: '动帐类型不能为空'}">
              <type v-model="form.type" />
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="form.type=='COMMISSION'">
            <el-form-item label="单号" prop="dealUuid" :rules="{ required: true, message: '单号不能为空'}">
              <deal v-model="form.dealUuid" placeholder="请选择单号" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="金额" prop="amount" :rules="{ required: true, message: '金额不能为空'}">
              <el-input v-model.number="form.amount" placeholder="请输入分佣金额"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="余额" prop="balance" :rules="{ required: true, message: '余额不能为空'}">
              <el-input v-model.number="form.balance" placeholder="请输入余额"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="form.type=='WITHDRAW'">
            <el-form-item label="打款人" prop="payer" :rules="{ required: true, message: '打款人名称不能为空'}">
              <el-input v-model="form.payer" placeholder="请输入打款人名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="form.type=='WITHDRAW'">
            <el-form-item label="收款方式" prop="payment" :rules="{ required: true, message: '收款方式不能为空'}">
              <el-input v-model="form.payment" placeholder="请输入收款方式"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="form.type=='WITHDRAW'">
            <el-form-item label="户名" prop="accountName" :rules="{ required: true, message: '户名不能为空'}">
              <el-input v-model="form.accountName" placeholder="请输入户名"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="form.type=='WITHDRAW'">
            <el-form-item label="账号" prop="bankAccount" :rules="{ required: true, message: '账号不能为空'}">
              <el-input v-model="form.bankAccount" placeholder="请输入账号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="备注" prop="note">
              <el-input v-model="form.note" type="textarea" :autosize="{minRows: 4, maxRows: 12}" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
 <script>
import inviter from "@/views/Promoter/components/form/inviter.vue";
import deal from "@/views/Deal/components/form/deal.vue";
import type from "@/views/Commission/components/form/type.vue";
export default {
  components: {
    inviter,
    deal,
    type
  },
  data () {
    return {
      type: 'add',
      dialogVisible: false,
      form: {
        commissionUuid: null,
        type: 'COMMISSION',
        amount: null,
        payer: null,
        payment: null,
        accountName: null,
        bankAccount: null,
        dealUuid: null,
        note: null,
        promoterUuid: null,
        balance: null
      },
    };
  },
  mounted () { },
  methods: {
    show ({ type = "add", data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
        console.log(data)
      }
      this.type = type;
      this.dialogVisible = true;
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.postCommission()
        } else {
          return false
        }
      })
    },
    closed () {
      this.form = {
        commissionUuid: null,
        type: 'COMMISSION',
        amount: null,
        payer: null,
        payment: null,
        accountName: null,
        bankAccount: null,
        dealUuid: null,
        note: null,
        promoterUuid: null,
        balance: null
      }
      this.$refs.form.resetFields()
    },
    // 添加
    async postCommission () {
      const api = {
        revision: {
          func: this.$api.putCommission,
          params: {
            ...this.form,
            commissionUuid: this.form.uuid
          }
        },
        add: {
          func: this.$api.postCommission,
          params: {
            ...this.form,
          }
        }
      }
      const { status, data, info } = await api[this.type].func(api[this.type].params)
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$emit('success', this.type)
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }

    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>